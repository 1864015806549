.radio ~ div {
  background-color: #282234;
  color: #ffffff;
}

.radio:checked ~ div {
  background-image: linear-gradient(to top, #3c1a80 3%, #5f439a 100%);
  /* background-color: #5f439a; */
}

.radio ~ * .radio_btn_container {
  color: #ffffff;
  background-color: black;
}

/* .radio:checked ~ * .radio_btn_container {
  background-color: white;
} */

.radio:checked ~ .radio_btn_container::before {
  content: '';
  /* Size and position the circle */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white; /* Color of the circle */
  /* Position the circle inside the radio button */
  position: absolute;
  top: 4px; /* Adjust as needed for positioning */
  left: 4px; /* Adjust as needed for positioning */
}

.radio:checked ~ div span {
  color: white;
}

.radio ~ * .check_icon {
  display: none;
}

.radio:checked ~ * .check_icon {
  display: block;
}
